<template>
  <div class="cardTopupView">
    <div id="cardTopup-searchView" v-if="showQuery">
      <div class="searchView" v-loading="queryLoading" element-loading-text="查询中...">
        <div class="tView">查询将要退卡的会员卡</div>
        <div class="imgView">
          <img src="https://static.bokao2o.com/wisdomCashier/images/Card_Find.png">
        </div>
        <div class="inputView">
          <input placeholder="输入手机号搜索" ref="query" v-model="phone" @keyup.enter="handleQuery"></input>
          <button style="border: none;
  outline: none;" :class="{'btn-search':phone !== ''}" :disabled="phone === ''" @click="handleQuery">查询
          </button>
        </div>
      </div>
    </div>
    <div class="topupInfoView" v-if="!showQuery">
      <div class="contentView" v-loading="refundLoading" element-loading-text="退卡中...">
        <div class="leftView">
          <div class="nameView">
            <label class="label-leftText">会员姓名</label>
            <div>
              <div class="inputView">
                <input :value="userInfo.realName" disabled="true"></input>
              </div>
            </div>
          </div>
          <div class="nameView">
            <label class="label-leftText">手机号码</label>
            <div>
              <div class="inputView"> 
                <input :value="userInfo.phone"  disabled="true"></input>
              </div>
            </div>
          </div>
          <div class="nameView">
            <label class="label-leftText">卡号</label>
            <div>
              <div class="inputView" v-if="userInfo.memberUser">
                <input disabled="true" :value="userInfo.memberUser.cardNo"></input>
              </div>
            </div>
          </div>
          <!--                    <div class="nameView">-->
          <!--                        <label class="label-leftText">会员地址</label>-->
          <!--                        <div>-->
          <!--                            <div class="inputView" v-if="userInfo.userAddress">-->
          <!--                                <input :value="userInfo.userAddress" disabled="true"></input>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
<!--          <div class="nameView">-->
<!--            <label class="label-leftText">办卡时间</label>-->
<!--            <div>-->
<!--              <div class="inputView" v-if="userInfo.lastCardRecord">-->
<!--                <input :value="userInfo.lastCardRecord.createTime" disabled="true"></input>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="nameView">
            <label class="label-leftText">到期时间</label>
            <div>
              <div class="inputView" v-if="userInfo.memberUser">
                <input :value="userInfo.memberUser.expireDate | date1" disabled="true"></input>
              </div>
            </div>
          </div>

          <div class="nameView">
            <label class="label-leftText">办卡金额</label>
            <div>
              <div class="inputView">
                <input :value="userInfo.totalCardPrice" disabled="true"></input>
              </div>
            </div>
          </div>
          <div class="nameView">
            <label class="label-leftText">权益优惠</label>
            <div>
              <div class="inputView">
                <input :value="userInfo.totalDiscountAmount" disabled="true"></input>
              </div>
            </div>
          </div>
          <div class="nameView">
            <label class="label-leftText">应退金额</label>
            <div>
              <div class="inputView">
                <input :value="userInfo.refundPrice" disabled="true"></input>
              </div>
            </div>
          </div>
          <!--                  <div class="nameView">-->
          <!--                    <label class="label-leftText">办卡门店</label>-->
          <!--                    <div>-->
          <!--                      <div class="inputView" v-if="userInfo.lastCardRecord">-->
          <!--                        <input :value="userInfo.lastCardRecord.storeName" disabled="true"></input>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <div class="nameView">
            <label class="label-leftText">退款原因</label>
            <div>
              <div class="inputView">
                <input placeholder="请输入退款原因" v-model="userInfo.reason"/>
              </div>
            </div>
          </div>
          <div class="nameView" style="display: flex;
    justify-content: flex-end;">
             <el-button :disabled="selectArr.length==0" class="btn-save" type="primary" @click="refundCard" >确定退款</el-button>
          </div>
        </div>
        <div class="rightView">
          <!--                    <div class="nameView">-->
          <!--                        <label class="label-leftText">办卡金额</label>-->
          <!--                        <div>-->
          <!--                            <div class="inputView" v-if="userInfo.lastCardRecord">-->
          <!--                                <input :value="userInfo.lastCardRecord.cardPrice" disabled="true"></input>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
          <!--                    <div class="nameView">-->
          <!--                        <label class="label-leftText">权益优惠</label>-->
          <!--                        <div>-->
          <!--                            <div class="inputView" v-if="userInfo.lastCardRecord">-->
          <!--                                <input :value="userInfo.memberUser.discountAmount" disabled="true"></input>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
          <!--                    <div class="nameView">-->
          <!--                        <label class="label-leftText">应退金额</label>-->
          <!--                        <div>-->
          <!--                            <div class="inputView">-->
          <!--                                <input :value="userInfo.refundPrice" disabled="true"></input>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
          <!--                    <div class="nameView">-->
          <!--                        <label class="label-leftText">办卡门店</label>-->
          <!--                        <div>-->
          <!--                            <div class="inputView" v-if="userInfo.lastCardRecord">-->
          <!--                                <input :value="userInfo.lastCardRecord.storeName" disabled="true"></input>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
          <!--                    <div class="nameView">-->
          <!--                        <label class="label-leftText">退款原因</label>-->
          <!--                        <div>-->
          <!--                            <div class="inputView">-->
          <!--                                <input placeholder="请输入退款原因" v-model="userInfo.reason"></input>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
          <div style="font-size: 14px;
    color: #666;
    position: absolute;
    top: -20px;
    right: 0;">(默认展示10条数据)</div>
          <div>
            <el-table
                border
                ref="multipleTable"
                tooltip-effect="dark"
                height="500"
                :data="userInfo.cardRecordList"
                @selection-change="handleSelectionChange"
                style="width: 100%">
                <el-table-column
                
      type="selection"
     :selectable="selectable" 
      width="55">
    </el-table-column>
              <el-table-column
                  prop="cardName"
                  label="卡名"
                  align="center"
                  width="120">
              </el-table-column>
               <el-table-column
                  prop="channelName"
                  label="办卡渠道"
                  align="center"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="办卡时间"
                  align="center"
                  width="180"
                  show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                  prop="payWayStr"
                  label="支付方式"
                  align="center">
              </el-table-column>
              <el-table-column
                  prop="cardPrice"
                  label="办卡金额"
                  align="center">
              </el-table-column>
                <el-table-column
                  prop="discountAmount"
                  label="权益优惠"
                  align="center">
              </el-table-column>
                <el-table-column
                  prop="refundPrice"
                  label="应退金额"
                  align="center">
              </el-table-column>
              <el-table-column
                  prop="storeName"
                  label="办卡门店"
                  align="center">
              </el-table-column>
            </el-table>
          </div>

        </div>
      </div>


    </div>

  </div>
</template>

<script>
import { isMobile, isPhone } from "@/utils/validate";
import { getUserInfoRefundCard } from "@/api/store/user";
import { refundMemberCard } from "@/api/member/user";

export default {
  name: "card",
  data() {
    return {
      visible: false,
      phone: "",
      showQuery: true,
      queryLoading: false,
      refundPrice: "0",
      userInfo: {
        reason: "",
        refundPrice: "0",
        userId: "",
        userRecordId: "",
        memberUser: {
          discountAmount: "",
        },
        refundRecordList: [],
        lastCardRecord: {
          storeName: "",
          cardPrice: "",
          createTime: "",
          validTime: "",
        },
      },
      refundLoading: false,
      selectArr: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.query.focus();
    });
  },
  methods: {
    // 处理查询
    handleQuery() {
      if (!this.phone) {
        this.$message.error("请输入手机号!");
        return false;
      } else {
        if (!isMobile(this.phone) || !isPhone(this.phone)) {
          this.$message.error("请输入正确手机号！");
          return false;
        }
      }
      this.getUser();
    },
    // 确定
    getUser() {
      this.queryLoading = true;
      const params = new URLSearchParams();
      params.append("phoneOrCardNum", this.phone);
      params.append("type", 1);
      getUserInfoRefundCard(params).then((response) => {
        this.queryLoading = false;
        if (response.data.code == 0) {
          this.userInfo = response.data.data;
          this.userInfo.phone=this.userInfo.phone?this.userInfo.phone.slice(0, 3) + "****" + this.userInfo.phone.slice(7):'';
          this.userInfo.memberUser.cardNo=this.userInfo.memberUser.cardNo?this.userInfo.memberUser.cardNo.slice(0, 3) + "****" + this.userInfo.memberUser.cardNo.slice(7):''
          this.userInfo.totalCardPrice = 0;
          this.userInfo.totalDiscountAmount = 0;
          this.userInfo.refundPrice = 0;
          //由后端判断
          // if (!this.userInfo.userId) {
          //   this.$message.error("该用户没有办卡，不可以操作退卡！");
          //   return false;
          // }
          // if (this.userInfo.rechargeFlag === 2) {
          //   this.$message.error("充值用户请先余额退款！");
          //   return false;
          // }
          // if (this.userInfo.userType === 1) {
          //   this.$message.error("该用户不是会员，不可以操作退卡！");
          //   return false;
          // }
          // if (!this.userInfo.cardRecordList && this.userInfo.userType === 2) {
          //   this.$message.error("不是本店权益会员，请到办卡门店操作退卡");
          //   return false;
          // }
          // this.userInfo.userRecordId = this.userInfo.lastCardRecord.id;
          // this.userInfo.refundPrice = this.userInfo.lastCardRecord.cardPrice - this.userInfo.memberUser.discountAmount < 0  ? 0 : this.userInfo.lastCardRecord.cardPrice - this.userInfo.memberUser.discountAmount;
          // this.userInfo.lastCardRecord.refundPrice = this.userInfo.refundPrice;
          // this.userInfo.refundRecordList = [];
          // this.userInfo.refundRecordList.push(this.userInfo.lastCardRecord);
          this.showQuery = false;
        } else {
          this.$message.error(response.data.msg);
        }
      });
    },
    refundCard() {
      if (this.userInfo.refundPrice === 0) {
        this.$message.error("应退金额为0，无法退款");
        return false;
      }

      if (!this.userInfo.reason) {
        this.$message.error("请填写退款原因！");
        return;
      }

      this.$confirm(
        "退款后将不再享受相应会员权益，退款金额原路返回，请谨慎操作",
        "退卡操作提醒",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }
      ).then(() => {
        this.refundLoading = true;
        this.userInfo.businessType = 2;
        refundMemberCard({...this.userInfo,cardRecordList:this.selectArr})
          .then((response) => {
            this.refundLoading = false;
            if (response.data.code === 0) {
              this.$message.success("退卡成功!！");
              this.$router.push({ name: "home" });
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch((res) => {
            this.refundLoading = false;
          });
      });
    },
    handleSelectionChange(arr) {
      this.selectArr = arr;
      this.userInfo.totalCardPrice = 0;
      this.userInfo.totalDiscountAmount = 0;
      this.userInfo.refundPrice = 0;
      arr?.forEach((item) => {
        item.cardPriceValue=item.cardPrice*100
        item.discountAmountValue=item.discountAmount*100
        item.refundPriceValue=item.refundPrice*100
        this.userInfo.totalCardPrice =
          this.userInfo.totalCardPrice + item.cardPriceValue;
        this.userInfo.totalDiscountAmount =
          this.userInfo.totalDiscountAmount + item.discountAmountValue;
        this.userInfo.refundPrice =
          this.userInfo.refundPrice + item.refundPriceValue;
      });
        this.userInfo.totalCardPrice=  this.userInfo.totalCardPrice/100
        this.userInfo.totalDiscountAmount=  this.userInfo.totalDiscountAmount/100
        this.userInfo.refundPrice=  this.userInfo.refundPrice/100
    },
    selectable(row,index){
         if(row.refundPrice ==0){
           return false;//禁用状态
         }else{
           return true;//非禁用状态
         }
       },
  },
};
</script>

<style scoped lang="scss">
// button {
//   background: transparent;
//   border: none;
//   outline: none;
//   box-sizing: border-box;
//   cursor: pointer;
// }

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input {
  box-sizing: border-box;
  border: none;
  font-size: 14px;
  outline: none;
}

.cardTopupView {
  position: relative;
  background: #fff;

  > .topupInfoView {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 25px;

    > .contentView {
      display: flex;
      width: 100%;
      padding: 0 40px 0 40px;

      > .leftView {
        flex: 4;

        > div {
          display: flex;
          margin-bottom: 12px;

          > .label-leftText {
            width: 85px;
            line-height: 48px;
            font-size: 16px;
            color: #666;
          }

          > div {
            flex: 1;

            > .inputView {
              width: 100%;
              background: #f8f8f8;
              border-radius: 5px;
              overflow: hidden;
              height: 48px;

              > input {
                display: block;
                width: 100%;
                padding: 12px 15px;
                line-height: 24px;
                font-size: 16px;
                color: #333;
                background: transparent;
                border: none;
                outline: none;
              }
            }
          }
        }
      }

      > .rightView {
         flex: 6;
        min-width: 200px;
        text-align: center;
        margin-left: 20px;
        position: relative;

        > div {
          display: flex;
          margin-bottom: 12px;

          > .label-leftText {
            width: 85px;
            line-height: 48px;
            font-size: 16px;
            color: #666;
          }

          > div {
            flex: 1;

            > .inputView {
              width: 100%;
              background: #f8f8f8;
              border-radius: 5px;
              overflow: hidden;
              height: 48px;

              > input {
                display: block;
                width: 100%;
                padding: 12px 15px;
                line-height: 24px;
                font-size: 16px;
                color: #333;
                background: transparent;
                border: none;
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}
.btn-save {
      // position: fixed;
      // right: 57%;
      // bottom: 32px;
      
      width: 190px;
      height: 40px;
      line-height: 40px;
      font-family: PingFangSC-Semibold;
      padding: 0;
    }
.btn-blue {
  background: #3370FF;
  font-size: 16px;
  border-radius: 6px;
  color: #fff;
}

.cardTopupView #cardTopup-searchView {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;

  > .searchView {
    position: relative;
    width: 400px;
    height: 280px;
    padding: 25px 30px;
    border-radius: 15px;
    left: 0;
    right: 0;
    margin: 15% auto 0 auto;
    background: #fff;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    -webkit-transition: -webkit-transform 0.7s, background 0.7s, font-size 0.7s;

    > .tView {
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold;
      color: #333;
      line-height: 45px;
      margin-bottom: 10px;
    }

    > .imgView {
      text-align: center;

      > img {
        width: 262px;
        height: 86px;
      }
    }

    > .inputView {
      display: flex;
      margin-top: 30px;
      background: #f8f8f8;
      border-radius: 6px;
      overflow: hidden;
      height: 44px;

      > input {
        flex: 1;
        padding: 10px 15px;
        font-size: 15px;
        line-height: 24px;
        border: none;
        background: #f8f8f8;
      }

      > button {
        width: 64px;
        height: 44px;
        padding: 0;
        line-height: 44px;
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #fff;
        background: #eee;
      }

      > button.btn-search {
        background: #3370ff;
      }
    }
  }
}
</style>
