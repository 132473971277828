<template>
    <div class="cardTopupView">
        <div id="cardTopup-searchView" v-if="showQuery">
            <div class="searchView" v-loading="queryLoading" element-loading-text="查询中...">
                <div class="tView">查询会员卡余额信息</div>
                <div class="imgView">
                    <img src="https://static.bokao2o.com/wisdomCashier/images/Card_Find.png">
                </div>
                <div class="inputView">
                    <input ref="query" v-model="phone" @keyup.enter="handleQuery" placeholder="输入手机号搜索"></input>
                    <button :class="{'btn-search':phone !== ''}" :disabled="phone === ''" @click="handleQuery">搜索</button>
                </div>
            </div>
        </div>
        <div class="topupInfoView" v-if="!showQuery" v-loading="refundLoading" element-loading-text="退款中...">
            <div class="cusView">
                <div class="tView">会员信息</div>
                <div class="cardInfoView">
                    <div class="baseInfoView">
                        <div>
                            <label>会员姓名: </label>
                            <label>{{userInfo.realName}}</label>
                        </div>
                        <div>
                            <label>手机号码: </label>
                            <label>{{userInfo.phone}}</label>
                        </div>
                        <div>
                            <label>会员地址: </label>
                            <label>{{userInfo.userAddress}}</label>
                        </div>
                        <div>
                            <label>充值余额: </label>
                            <label>【{{userInfo.memberUser.capital}}】 元</label>
                        </div>
                        <div>
                            <label>赠送余额: </label>
                            <label>【{{userInfo.memberUser.give}}】 元</label>
                        </div>
                        <div>
                            <label>应退金额: </label>
                            <label>{{refundPrice}} </label>
                        </div>
                        <div>
                            <label>退款原因: </label>
                            <label>
                                <el-input placeholder="请输入退款原因" v-model="refundReason" maxlength="15"
                                          show-word-limit></el-input>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="topupInfoView">
                <div class="tView">充值记录</div>
                <div class="rechargeView">
                    <el-table
                            border
                            ref="multipleTable"
                            tooltip-effect="dark"
                            height="450"
                            :data="userInfo.chargeRecordList"
                            style="width: 100%"
                            @selection-change="handleSelectionChange">
                        <el-table-column
                                type="selection"
                                width="55">
                        </el-table-column>
                        <el-table-column
                                prop="cardName"
                                label="卡名"
                                align="center"
                                width="200">
                        </el-table-column>
                        <el-table-column
                                prop="createTime"
                                label="时间"
                                align="center"
                                width="280"
                                show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                                prop="payWayStr"
                                label="支付方式"
                                align="center">
                        </el-table-column>
                        <el-table-column
                                prop="cardPrice"
                                label="充值金额"
                                align="center">
                        </el-table-column>
                        <el-table-column
                                prop="refundPrice"
                                label="可退金额"
                                align="center">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="btnView">
                    <button class="btn-buy" @click="submit">确定退款</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {isMobile, isPhone} from '@/utils/validate'
    import {getUserInfoRefundBalance} from "@/api/store/user";
    import {refundCard} from "@/api/member/user";

    export default {
        name: "balance",
        data() {
            return {
                phone: '',
                showQuery: true,
                queryLoading: false,
                refundReason: '',
                refundPrice: 0,
                userInfo: {
                    refundPrice: 0,
                    userId: '',
                    userRecordId: '',
                    memberUser: {
                        discountAmount: '',
                    },
                    chargeRecordList: [],
                    refundRecordList: [],
                    lastCardRecord: {
                        storeName: '',
                        cardPrice: '',
                        createTime: '',
                        validTime: '',
                    }
                },
              refundLoading: false
            }
        },
        methods: {
            // 处理查询
            handleQuery() {
                if (!this.phone) {
                    this.$message.error('请输入手机号!');
                    return false
                } else {
                    if (!isMobile(this.phone) || !isPhone(this.phone)) {
                        this.$message.error('请输入正确手机号/电话号码!');
                        return false
                    }
                }
                this.getUser()
            },
            // 确定
            getUser() {
                this.queryLoading = true
                const params = new URLSearchParams()
                params.append('phoneOrCardNum', this.phone)
                params.append('type', 2)
              getUserInfoRefundBalance(params).then(response => {
                    this.queryLoading = false
                    if(response.data.code == 1){
                        this.$message.error(response.data.msg);
                        return false
                    }
                    this.userInfo = response.data.data;
                    this.showQuery = false;
                })
            },
            handleSelectionChange(val) {
                this.refundPrice = 0;
                this.userInfo.refundRecordList = [];
                for (let i = 0; i < val.length; i++) {
                    this.refundPrice = this.refundPrice + val[i].refundPrice
                    this.userInfo.refundRecordList.push(val[i]);
                }
            },
            submit() {
                if (this.refundPrice === 0) {
                    this.$message.error('请选择要退款的充值记录');
                    return false
                }
              if (!this.refundReason) {
                this.$message.error('请填写退款原因!');
                return false
              }
                this.$confirm('余额退款只退回充值本金，赠额不进行退回，请谨慎操作', '操作提醒', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error'
                }).then(() => {
                  this.refundLoading = true
                    this.userInfo.businessType = 3;
                    this.userInfo.reason=this.refundReason
                    refundCard(this.userInfo).then(response => {
                        if (response.data.code === 0) {
                          this.refundLoading = false
                            this.$message.success('退款成功!');
                            this.$router.push({name: 'home'})
                        } else {
                          this.refundLoading = false
                            this.$message.error(response.data.msg);
                        }
                    }).catch(res=>{
                      this.refundLoading = false
                    })
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    button {
        background: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        cursor: pointer;
    }

    div {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    input {
        box-sizing: border-box;
        border: none;
        font-size: 14px;
        outline: none;
    }

    .cardTopupView {
        position: relative;
        background: #fff;

        > .topupInfoView {
            display: flex;
            width: 100%;
            height: 100%;
            padding-top: 25px;

            > .cusView {
                width: 410px;
                padding: 0 0 15px 0;
                border-right: 0.5px solid rgba(220, 220, 220, 0.7);
                overflow-x: hidden;
                overflow-y: auto;
                max-width: 40%;

                > .tView {
                    padding: 0 30px;
                    font-family: PingFangSC-Semibold;
                    font-size: 20px;
                    color: #333;
                    line-height: 30px;
                }

                > .cardInfoView {
                    padding: 25px 30px;
                    line-height: 30px;

                    > .baseInfoView {
                        /*padding: 0 40px 25px 40px;*/
                        > div {
                            /*display: flex;*/
                            font-size: 15px;

                            > label:first-child {
                                color: #999;
                            }

                            > label:last-child {
                                /*flex: 1;*/
                                color: #333;
                                text-align: right;
                            }
                        }
                    }
                }
            }

            > .topupInfoView {
                flex: 1;
                padding: 0 0px 0px 55px;
                overflow-x: hidden;
                overflow-y: auto;

                > .tView {
                    padding: 0 30px;
                    font-family: PingFangSC-Semibold;
                    font-size: 20px;
                    color: #333;
                    line-height: 30px;
                }

                > .rechargeView {
                    padding: 25px 30px;
                    line-height: 30px;
                }

                > .btnView {
                    text-align: center;

                    > button {
                        width: 340px;
                        height: 44px;
                        background: #3370ff;
                        border-radius: 6px;
                        line-height: 44px;
                        padding: 0;
                        font-size: 16px;
                        color: #fff;
                    }
                }
            }
        }
    }

    .cardTopupView #cardTopup-searchView {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;

        > .searchView {
            position: relative;
            width: 400px;
            height: 280px;
            padding: 25px 30px;
            border-radius: 15px;
            left: 0;
            right: 0;
            margin: 15% auto 0 auto;
            background: #fff;
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
            -webkit-transition: -webkit-transform 0.7s, background 0.7s, font-size 0.7s;

            > .tView {
                text-align: center;
                font-size: 16px;
                font-family: PingFangSC-Semibold;
                color: #333;
                line-height: 45px;
                margin-bottom: 10px;
            }

            > .imgView {
                text-align: center;

                > img {
                    width: 262px;
                    height: 86px;
                }
            }

            > .inputView {
                display: flex;
                margin-top: 30px;
                background: #f8f8f8;
                border-radius: 6px;
                overflow: hidden;
                height: 44px;

                > input {
                    flex: 1;
                    padding: 10px 15px;
                    font-size: 15px;
                    line-height: 24px;
                    border: none;
                    background: #f8f8f8;
                }

                > button {
                    width: 64px;
                    height: 44px;
                    padding: 0;
                    line-height: 44px;
                    font-family: PingFangSC-Semibold;
                    font-size: 16px;
                    color: #fff;
                    background: #EEE;
                }

                > button.btn-search {
                    background: #3370ff;
                }
            }


        }
    }
</style>
