<template>
  <div class="refundRecordView">
    <div class="filtrate">
      <el-form ref="form" :model="formData" label-width="10px">
        <el-form-item>
          <el-date-picker
            v-model="dates"
            type="daterange"
            align="right"
            size="medium"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            @change="selectTime"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="userInfo">
          <el-input
            placeholder="请输入内容"
            size="medium"
            v-model="formData.userInfo"
            class="input-with-select"
          >
            <el-select
              v-model="formData.userType"
              slot="prepend"
              placeholder="请选择"
              size="medium"
              style="width: 100px;"
            >
              <el-option label="用户名" value="1"></el-option>
              <el-option label="手机号" value="2"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item prop="refundType">
          <el-select
            v-model="formData.refundType"
            placeholder="请选择退款类型"
            size="medium"
            clearable
          >
            <el-option
              :label="item.text"
              :value="item.value"
              v-for="item in refundTypeList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="queryInfo">
            查询
          </el-button>
          <el-button size="medium" @click="reSet">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div
      class="contentView"
      v-loading="refundRecordLoading"
      element-loading-text="加载中..."
    >
      <div class="tView flexView">
        <label>类型</label>
        <label>流水号</label>
        <label>用户姓名</label>
        <label>联系方式</label>
        <label>订单金额</label>
        <label>退款金额</label>
        <label>状态</label>
        <label>退款方式</label>
        <label>门店</label>
        <label>退款时间</label>
        <label>操作</label>
      </div>
      <div class="listView" >
        <div
          class="listItem flexView"
          v-for="(item, index) in refundRecordList"
          :key="index"
        >
          <label v-if="item.refundTypeName === '1'">收衣退单</label>
          <label v-else-if="item.refundTypeName === '2'">会员退卡</label>
          <label v-else-if="item.refundTypeName === '3'">余额退款</label>
          <label>{{ item.refundId }}</label>
          <label>{{ item.realName }}</label>
          <label v-hidephone>{{ item.phone }}</label>
          <label>¥{{ item.totalFee }}</label>
          <label>¥{{ item.refundFee }}</label>
          <label>{{ item.refundStatus == 1 ? '成功' : '失败' }}</label>
          <label>{{ item.refundWay }}</label>
          <label>{{ item.storeName }}</label>
          <label>{{ item.createTime }}</label>
          <label>
            <el-button
              type="primary"
              size="mini"
              @click="getRefundRecordDetail(item)"
            >
              详情
            </el-button>
          </label>
        </div>
        <div style="margin-top: 20px; text-align: right;">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="formData.current"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="formData.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <pop-view
      id="refundViewPopView"
      :show="dialogVisible"
      width="900px"
      top="10%"
      title="退款详情"
      @cancel="dialogVisible = false"
    >
      <div
        class="res"
        v-loading="refundViewLoading"
        element-loading-text="加载中..."
      >
        <div class="detail_left">
          <p class="title">用户信息</p>
          <ul>
            <li>
              <span>用户姓名：</span>
              <span>{{ refundRecordUser.userName }}</span>
            </li>
            <li>
              <span>用户账号：</span>
              <span v-hidephone>{{ refundRecordUser.phone }}</span>
            </li>
          </ul>
        </div>
        <div class="detail_right">
          <p class="title">订单信息</p>
          <ul>
            <li>
              <span>订单金额：</span>
              <span>{{ refundRecordDetail.totalFee }}元</span>
            </li>
            <li>
              <span>退款金额：</span>
              <span>{{ refundRecordDetail.refundFee }}元</span>
            </li>
            <li>
              <span>运费金额：</span>
              <span>{{ refundRecordDetail.fareAmount }}</span>
            </li>
            <li>
              <span>退款门店：</span>
              <span>{{ refundRecordDetail.storeName }}</span>
            </li>
            <li>
              <span>操作人：</span>
              <span>{{ refundRecordDetail.storeUserName }}</span>
            </li>
          </ul>
        </div>

        <div class="detail_bottom">
          <p class="title">衣物信息</p>
          <table class="table">
            <tr>
              <th>项目名称</th>
              <th>流水号</th>
              <!-- <th>颜色</th>
              <th>损坏环节</th> -->
              <th>预计退款金额</th>
              <th>实际退款金额</th>
              <!-- <th>损坏原因</th> -->
            </tr>
            <tr
              v-for="(item, index) in refundRecordDetail.refundDetailList"
              :key="index"
            >
              <td>{{ item.clothesName }}</td>
              <td>{{ item.clothesNumber }}</td>
              <!-- <td>{{ item.clothesColor }}</td>
              <td>{{ item.clothesStatus }}</td> -->
              <td>{{ item.estimateRefundFee }}</td>
              <td>{{ item.refundFee }}</td>
              <!-- <td>{{ item.refundReason }}</td> -->
            </tr>
          </table>
        </div>
      </div>
    </pop-view>
  </div>
</template>

<script>
import {
  refundRecordPage,
  getRefundRecordByRefundId,
} from '@/api/clothes/order'
import PopView from '@/components/PopView'

export default {
  name: 'record',
  components: {
    PopView,
  },
  data() {
    return {
      dialogVisible: false,
      total: 0, // 总数量
      type: '1',
      refundRecordList: [],
      value1: '',
      refundRecordDetail: {},
      refundRecordUser: {},
      refundRecordLoading: false,
      refundViewLoading: false,
      formData: {
        current: 1,
        size: 10,
        userInfo: '',
        userType: '1',
        refundType: '',
        beginTime: '',
        endTime: '',
      },
      dates: null,
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, start])
            },
          },
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime()
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 90 * 24 * 3600 * 1000
            const minTime = this.selectDate - one
            const maxTime = this.selectDate + one
            return (
              time.getTime() < minTime ||
              time.getTime() > maxTime ||
              time.getTime() > Date.now() - 8.64e6
            )
          }
        },
      },
      refundTypeList: [
        { value: 1, text: '订单退款' },
        { value: 2, text: '权益卡退款' },
        { value: 3, text: '充值卡退款' },
      ],
      domHeight: 50,
    }
  },
  created() {
    this.refundRecordPage()
  },
  mounted() {
    this.domHeight = document.body.clientHeight - 230 + 'px'
  },
  methods: {
    refundRecordPage() {
      this.refundRecordLoading = true
      // const params = new URLSearchParams()
      // params.append('current', this.currentPage)
      // params.append('size', this.pageSize)
      refundRecordPage(this.formData).then((res) => {
        this.refundRecordLoading = false
        console.log(res)
        this.refundRecordList = res.data.data.xytRefundRecordList
        this.total = res.data.data.total
      })
    },
    handleSizeChange(val) {
      this.formData.size = val
      this.refundRecordPage()
    },
    handleCurrentChange(val) {
      this.formData.current = val
      this.refundRecordPage()
    },
    getRefundRecordDetail(item) {
      this.refundViewLoading = true
      this.type = item.refundTypeName
      getRefundRecordByRefundId(item.refundId).then((res) => {
        this.refundViewLoading = false
        console.log(res)
        this.refundRecordDetail = res.data.data
        this.refundRecordUser = res.data.data.refundRecordUser
        this.dialogVisible = true
      })
    },
    reSet() {
      this.$refs.form.resetFields()
      this.dates = null
      this.selectTime()
      this.refundRecordPage()
    },
    selectTime() {
      this.formData.beginTime = this.dates ? this.dates[0] : ''
      this.formData.endTime = this.dates ? this.dates[1] : ''
    },
    queryInfo() {
      this.formData.current = 1
      this.refundRecordPage()
    },
  },
}
</script>

<style scoped lang="scss">
.refundRecordView {
  text-align: center;

  .filtrate {
    // display: flex;
    // height: 44px;
    padding: 20px 25px 0 25px;
    .el-form {
      display: flex;
      .el-form-item__content .el-input-group {
        vertical-align: middle;
      }
    }
  }

  > .contentView {
    .tView {
      display: flex;
      padding: 15px 140px 15px 25px;

      > label {
        flex: 1;
      }

      > label:nth-child(2) {
        flex: 2;
      }

      > label:nth-child(10) {
        flex: 2;
      }
    }
    .listItem {
      display: flex;
      padding: 15px 25px ;
      > label {
        flex: 1;
      }

      > label:nth-child(2) {
        flex: 2;
      }

      > label:nth-child(10) {
        flex: 2;
      }
    }
  }

  .contentView {
    > .tView {
      line-height: 40px;
      padding: 0   25px;
      font-size: 13px;
      color: #666;
      text-align: center;
    }

    > .listView {
      background: #fff;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 25px;

      > .listItem {
        position: relative;
        line-height: 20px;
        font-size: 14px;
        color: #333;
        background: #f8f8f8;
        text-align: center;
      }

      > .listItem:nth-child(odd) {
        background: #fff;
      }

      > .listItem:hover {
        background: rgba(66, 153, 255, 0.1);
      }
    }
  }
}

// button {
//   // background: transparent;
//   border: none;
//   outline: none;
//   box-sizing: border-box;
//   cursor: pointer;
// }

.res {
  height: 100%;
  background: #fff;
  border-radius: 1px;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: auto;
  .detail_left,
  .detail_right {
    width: calc(50% - 12px);
    margin-right: 6px;
    margin-bottom: 6px;

    .title {
      text-align: center;
      background: #f6faff;
      height: 35px;
      line-height: 35px;
      font-size: 15px;
    }

    li {
      font-size: 15px;
      height: 35px;
      line-height: 35px;
      border-bottom: 1px solid #f0f3f6;
      padding-left: 12px;
      padding-right: 12px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }

  .detail_right {
    margin-right: 0;
    border-left: 1px solid #ccc;
    padding-left: 6px;
  }

  .detail_bottom {
    width: 100%;
    padding: 12px;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 5px;
    // box-shadow: 0 5px 5px 0 rgba(0, 116, 225, 0.22);

    .title {
      text-align: center;
      height: 35px;
      line-height: 35px;
      font-size: 15px;
      background-color: #f6faff;
    }

    .table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;

      tr {
        height: 35px;
        line-height: 35px;
        overflow: hidden;
        border-bottom: 1px dotted #ccc;
        font-size: 15px;

        th {
          font-size: 15px;
        }
      }
    }
  }
}
</style>
