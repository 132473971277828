import request from '@/utils/request'


//洗后付回款列表

export const getWashAfter = (params)=>{
    return request({
        url:'order/order/washAfterPayDaily',
        method:'GET',
        params
    })
}


/**
 * 查询所有的衣物订单
 */
export function getClothesOrderPage(parms) {
    return request({
        url: '/order/order',
        method: 'get',
        params: parms
    })
}

export function getClothesOrderDetails(orderNumber) {
    return request({
        url: '/order/order/clothesOrderDetails/' + orderNumber,
        method: 'get'
    })
}

export function getClothesOrderDetailsForSorting(orderNumber) {
    return request({
        url: '/order/order/clothesOrderDetailsForSorting/' + orderNumber,
        method: 'get'
    })
}

export function getTakeClothesOrderDetails(orderNumber) {
    return request({
        url: '/order/order/takeClothesOrderDetails/' + orderNumber,
        method: 'get'
    })
}

/**
 * 取衣
 */
export function takeClothes(params) {
    return request({
        url: '/order/order/listTakeClothes',
        method: 'get',
        params: params
    })
}

export function payCallbackUpdateOrder(params) {
    return request({
        url: '/order/order/payCallbackUpdateOrder',
        method: 'put',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        params: params
    })
}

/**
 * 支付订单
 * @param params
 */
export function payOrder(data) {
    return request({
        url: '/order/order/payOrder',
        method: 'post',
        data: data
    })
}

/**
 * 支付订单查询
 * @param params
 */
export function payOrderQuery(params) {
    return request({
        url: '/order/order/payOrderQuery',
        method: 'get',
        params: params
    })
}

/**
 * 撤销支付订单
 * @param params
 */
export function orderReverse(params) {
    return request({
        url: '/order/order/orderReverse',
        method: 'get',
        params: params
    })
}

export function orderRefund(data) {
    return request({
        url: '/order/order/orderRefund',
        method: 'post',
        data: data
    })
}
// 门店申请退款
export function orderRefundExamine(data) {
    return request({
        url: '/order/refund_examine/refund',
        method: 'post',
        data: data
    })
}

export function refundRecordPage(params) {
    return request({
        url: '/order/refundrecord/page',
        method: 'get',
        params: params
    })
}

export function getRefundRecordByRefundId(refundId) {
    return request({
        url: '/order/refundrecord/' + refundId,
        method: 'get'
    })
}


export function clothesOperateRecord(params) {
    return request({
        url: '/order/clothesOperateRecord/page',
        method: 'get',
        params: params
    })
}

export function listClothesOperateRecordDetais(serialNum) {
    return request({
        url: '/order/clothesOperateRecord/' + serialNum,
        method: 'get'
    })
}


export function listTimeOutClothesOrderPage(params) {
    return request({
        url: '/order/orderclothes/timeout/page',
        method: 'get',
        params: params
    })
}

/**
 * 查询待送洗衣物
 */
export function listWaitToWash() {
    return request({
        url: '/order/orderclothes/wait-to-wash',
        method: 'get',
    })
}

export function getOrderPrice(orderNumber) {
    return request({
        url: '/order/order/orderPrice/' + orderNumber,
        method: 'get',
    })
}

export function printPaper(orderNumber) {
    return request({
        url: '/order/order/printPaper/' + orderNumber,
        method: 'get',
    })
}

/**
 * 打印水洗唛
 * @param orderNumber
 */
export function printLabel(clothesNumList) {
    return request({
        url: '/order/order/printLabel',
        method: 'put',
        data: clothesNumList
    })
}

/**
 * 修改订单
 * @param data
 */
export function updateOrder(data) {
    return request({
        url: '/order/order/updateOrder',
        method: 'post',
        data: data
    })
}


/**
 * 取消订单
 * @param orderNumber
 */
export function cancelOrder(data) {
    return request({
        url: '/order/order/cashier/cancel_order',
        method: 'put',
        data: data
    })
}

//统计办卡
export function daily(parms) {
    return request({
        url: '/dwh/order/daily',
        method: 'get',
        params: parms
    })
}

// 分页查询线上订单
export function appletsReserveOrderPage(parms) {
    return request({
        url: '/order/order/appletsReserveOrderPage',
        method: 'get',
        params: parms
    })
}

// 接单
export function takeOrder(orderNumber) {
    return request({
        url: '/order/order/takeOrder/' + orderNumber,
        method: 'post'
    })
}


//*******************************************

/**
 * 查询待送洗衣物
 */
export function waitOperate(type) {
    return request({
        url: '/order/orderclothes/wait/operate/' + type,
        method: 'get',
    })
}

export function waitOperateCount(type) {
    return request({
        url: '/order/orderclothes/wait/operateCount/' + type,
        method: 'get',
    })
}

/**
 * 确认操作衣物
 */
export function queryClothes(type, clothesNum,id) {
    return request({
        url: '/order/orderclothes/query/' + type + '/' + clothesNum + '/' + id,
        method: 'get',
    })
}

/**
 * 确认操作衣物
 */
export function operate(data) {
    return request({
        url: '/order/orderclothes/operate',
        method: 'put',
        data: data
    })
}

/**
 * 获取预约数量
 * @param data
 */
export function getReserveOrderNum() {
    return request({
        url: '/order/order/getReserveOrderNum',
        method: 'get'
    })
}

/**
 * 根据衣物号查询衣物退款订单详情
 * @param orderNumber
 */
export function getClothesOrderForRefund(orderNumber) {
    return request({
        url: '/order/order/clothesOrderForRefund/' + orderNumber,
        method: 'get'
    })
}

export function getHomepageData(parms) {
    return request({
        url: '/order/order/getHomepageData',
        method: 'get',
        params: parms
    })
}
export function getHomeHeadData(parms) {
    return request({
        url: '/order/order/head/page_data',
        method: 'get',
        params: parms
    })
}

// 请求继续催单过来的数据

export const getOrderRemin = (orderNumber)=>{
	return request({
		method:"GET",
		url:`order/order/getReminderClothesOrderDetails/${orderNumber}`
	})
}

//发送催单信息
export const sendOrderRemin = (data)=>{
	return request({
		method:"POST",
		url:"factory/factoryReminder/addReminder",
		data
	})
}


/**
 * 回退上一步状态(回退送洗之前的状态)
 */
export function updateClothesSendBefore(params) {
    return request({
        url: '/order/clothesOperateRecord/updateClothesSendBefore',
        method: 'PUT',
        params: params,
    })
}

// 取衣页面显示多少衣物可取
export function myTakeClothes() {
    return request({
        url: '/order/order/myTakeClothes',
        method: 'get',
    })
}



/**
 * 检查订单衣物信息
 * @param userId
 * @param data
 */
export function checkOrderClothesInfo(userId,data) {
    return request({
        url: '/order/order/checkOrderClothesInfo/'+userId,
        method: 'post',
        data: data
    })
}
//2.0返洗接口

//查询门店所属区域是否开启物流
export function enableLogistics(params) {
    return request({
        url: '/sys/area/store/enable_logistics',
        method: 'get',
        params: params,
    })
}

//获取门店下已送洗、装衣中的包裹列表
export function getStoreBundle(params) {
    return request({
        url: '/logistics/bundle/getStoreBundle',
        method: 'get',
        params: params,
    })
}
//保存衣物到草稿箱
export function saveClothesToDraft(data) {
    return request({
        url: '/logistics/bundleDrafts/saveClothesToDraft',
        method: 'post',
        data: data,
    })
}
//减少衣物By草稿箱
export function reduceClothsToDraft(data) {
    return request({
        url: '/logistics/bundleDrafts/reduceClothsToDraft',
        method: 'post',
        data: data,
    })
}

//新增包裹
export function createdBundle(data) {
    return request({
        url: '/logistics/bundle/createdBundle',
        method: 'post',
        data: data
    })
}
//取消包裹
export function cancelBundleById(data) {
    return request({
        url: '/logistics/bundle/cancelBundleById',
        method: 'post',
        data: data
    })
}
//获取送洗包裹统计数据
export function getWashStatistics(params) {
    return request({
        url: '/logistics/bundle/getWashStatistics',
        method: 'get',
        params
    })
}
//打包送洗
export function bundlePack(data) {
    return request({
        url: '/logistics/bundle/bundlePack',
        method: 'post',
        data: data
    })
}
//包裹拆包
export function unpacking(params) {
    return request({
        url: '/logistics/bundle/unpacking',
        method: 'get',
        params: params
    })
}
//补打包裹号
export function repairBundle(params) {
    return request({
        url: '/logistics/bundle/repairBundle',
        method: 'get',
        params: params
    })
}
//根据包裹id获取包裹下的衣物
export function getBundleClothes(params) {
    return request({
        url: '/logistics/bundle/getBundleClothes',
        method: 'get',
        params: params
    })
}
//查询送洗记录列表
export function getBundleWashRecordPage(params) {
    return request({
        url: '/logistics/bundle/getBundleWashRecordPage',
        method: 'get',
        params: params
    })
}
//查询包裹详情
export function packageDetails(id,type) {
    return request({
        url: '/logistics/bundle/detail/' + id + '/' + type,
        method: 'get',
    })
}

//获取包裹状态/值Map
export function getBundleStatusMap() {
    return request({
        url: '/logistics/bundle/getBundleStatusMap',
        method: 'get',
    })
}


// 入店2.0

//入店包裹扫码
export function packageScanning(params){
    return request({
        url: '/logistics/bundle/inStore/scan',
        method: 'get',
        params
    })
}
//分页查询待入店包裹
export function incomingPackage(params){
    return request({
        url: '/logistics/bundle/waitFactory/list',
        method: 'get',
        params
    })
}
//店包裹及衣物统计
export function statistics(params){
    return request({
        url: '/logistics/bundle/waitFactory/statistics',
        method: 'get',
        params
    })
}
//门店衣物扫码入店
export function joinStore(params){
    return request({
        url: '/order/orderclothes/scan/joinStore',
        method: 'get',
        params
    })
}
//根据状态分页查询包裹
export function paginationPackage(params) {
    return request({
        url: '/logistics/bundle/status/page',
        method: 'get',
        params
    })
  }
  //分页查询包裹衣物(具体状态)
export function wrapClothes(params) {
    return request({
        url: '/logistics/bundle/clothes/page',
        method: 'get',
        params
    })
  }
  //衣物详情
  export function clothesDetails(clothesNum) {
    return request({
        url: '/order/orderclothes/'+ clothesNum,
        method: 'get',
    })
  }
//   分页查询包裹入店记录
  export function entryRecord(params) {
    return request({
        url: '/logistics/bundle/store/record',
        method: 'get',
        params
    })
  }
// 非旧包裹页面返回保存衣物到草稿箱
  export function bundleReturnSave(data) {
    return request({
        url: '/logistics/bundle/bundleReturnSave',
        method: 'post',
        data
    })
  }
// 取消拆包
  export function cancelUnpacking(params) {
    return request({
        url: '/logistics/bundle/cancelUnpacking',
        method: 'get',
        params
    })
  }
//   获取门店可用袋子数集合
export function getLogisticsAvailableBags(params) {
    return request({
        url: '/logistics/bundle//store/available_bags',
        method: 'get',
        params
    })
  }
//   查询门店袋子数据
export function getLogisticsStoreColorBagsNum() {
    return request({
        url: '/logistics/bundle/store/color_bags_num',
        method: 'get',
    })
  }
  // 分页查询退款审核列表
export const getRefundExamineList = (params) => {
    return request({
      method: 'GET',
      url: 'order/refund_examine/page',
      params,
    })
  }
  // 查询退款审核详情
  export const getRefundExamineDetail = (params) => {
    return request({
      method: 'GET',
      url: 'order/refund_examine/detail',
      params,
    })
  }
  // 审核通过/拒绝
  export const setRefundCheck = (data) => {
    return request({
      method: 'post',
      url: 'order/refund_examine/handle',
      data,
    })
  }
  // 删除
  export const delRefundCheck = (id) => {
    return request({
      method: 'delete',
      url: 'order/refund_examine/delete/' + id,
    })
  }
    // 查询门店退款审核数据
    export const getRefundCheckStatistics = (params) => {
        return request({
          method: 'get',
          url: 'order/refund_examine/status/statistics',
          params
        })
      }