<template>
  <div class="refundIndexView content-index">
    <div class="topView">
      <div class="tView">
        <el-page-header
          style="padding:10px"
          @back="$router.go(-1)"
          content="退款退赔"
      ></el-page-header>
      </div>
      <div class="menuView">
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ select: currentTab === tab.id }"
          @click="toggleTab(tab.id)"
        >
          {{ tab.title }}
        </button>
      </div>
    </div>
    <div class="contentView">
      <card v-if="currentTab === 0"></card>
      <balance v-if="currentTab === 1"></balance>
      <record v-if="currentTab === 2"></record>
    </div>
  </div>
</template>

<script>
import Card from "@/view/refund/components/card";
import Balance from "@/view/refund/components/balance";
import Record from "@/view/refund/components/record";

export default {
  name: "index",
  components: {
    Card,
    Balance,
    // Compensate,
    Record,
  },
  data() {
    return {
      tabs: [
        { id: 0, title: "会员退卡" },
        { id: 1, title: "余额退款" },
        { id: 2, title: "退赔记录" },
      ],
      currentTab: 0,
    };
  },
  methods: {
    toggleTab: function(index) {
      this.currentTab = index;
    },
  },
};
</script>

<style scoped lang="scss">
.refundIndexView {
  position: relative;
  font-family: PingFangSC-Regular;
  padding: 20px;
  overflow:auto;

  > .topView {
    > .tView {
      color: #333;
      font-size: 24px;
      font-family: PingFang-SC-Bold;
    }
    > .menuView {
      position: absolute;
      width: 380px;
      display: flex;
      top: 27px;
      left: 0;
      right: 0;
      margin: auto;

      > button.select {
        position: relative;
        color: #3370ff;
        font-family: PingFangSC-Semibold;
      }

      > button.select:after {
        content: "";
        left: 0;
        right: 0;
        bottom: -6px;
        position: absolute;
        width: 24px;
        height: 2px;
        background: #3370ff;
        margin: auto;
        border-radius: 1px;
      }

      > button {
        font-size: 16px;
        color: #333;
        flex: 1;
        line-height: 30px;
        height: 30px;
        padding: 0;
      }
    }
  }
}

button {
  background: transparent;
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>
