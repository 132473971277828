import request from '@/utils/request'

//办卡
export function applyCard(params) {
    return request({
        url: '/member/user/applyCard',
        method: 'post',
        data: params
    })
}

/**
 * 支付订单
 * @param params
 */
export function payOrder(data) {
    return request({
        url: '/member/user/payOrder',
        method: 'post',
        data: data
    })
}

/**
 * 充值支付订单
 * @param params
 */
export function payRecharge(data) {
    return request({
        url: '/member/user/payRecharge',
        method: 'post',
        data: data
    })
}

//充值
export function recharge(params) {
    return request({
        url: '/member/user/recharge',
        method: 'post',
        data: params
    })
}

/**
 * 支付订单查询
 * @param params
 */
export function payCardQuery(params) {
    return request({
        url: '/member/user/payCardQuery',
        method: 'get',
        params: params
    })
}

/**
 * 撤销支付订单
 * @param params
 */
export function cardReverse(params) {
    return request({
        url: '/member/user/cardReverse',
        method: 'get',
        params: params
    })
}
/**
 * 支付订单查询
 * @param params
 */
export function payRechargeQuery(params) {
   return request({
       url: '/member/user/payRechargeQuery',
       method: 'get',
       params: params
   })
}

//退充值
export function refundCard(params) {
    return request({
        url: '/member/user/refundCard',
        method: 'post',
        data: params
    })
}
//退充值
export function refundMemberCard(params) {
    return request({
        url: '/member/user/choose/refundMemberCard',
        method: 'post',
        data: params
    })
}

//获取会员卡记录列表
export function getCardRecordList(params) {
    return request({
        url: 'member/user/rechargeRecord',
        method: 'get',
        params: params
    })
}

//获取积分记录列表
export function getScoreRecordList(params) {
    return request({
        url: 'member/user/scoreRecord',
        method: 'get',
        params: params
    })
}

//获取资金流水记录
export function getUserMoneyRecordByUserId(params) {
    return request({
        url: 'order/orderclothes/queryAllClothesByUserId',
        method: 'get',
        params: params
    })
}

//获取会员详情页信息
export function getUserDetailById(userId) {
    return request({
        url: 'member/user/detail/' + userId,
        method: 'get',
    })
}

//修改用户信息
export function updateUser(params) {
    return request({
        url: 'member/user',
        method: 'put',
        data: params
    })
}

/**
 * 获取用户账户信息
 * @param userId
 */
export function getUserAccount(userId) {
    return request({
        url: 'member/user/userAccount/'+userId,
        method: 'get'
    })
}

/**
 * 设置标签
 * @param data
 */
export function settingUserLabel(data) {
    return request({
        url: 'member/user/settingUserLabel',
        method: 'put',
        data: data
    })
}

export function deleteUserLabel(params) {
    return request({
        url: 'member/user/deleteUserLabel',
        method: 'delete',
        params: params
    })
}

// 获取用户信息为了办卡
export function getUserInfoForApplyCard(params) {
    return request({
        url: 'member/user/getUserInfoForApplyCard',
        method: 'get',
        params: params
    })
}

// 会员卡升级查询校验
export function memberCardUpgradeCheck(params) {
    return request({
        url: 'member/user/upgrade/check',
        method: 'get',
        params: params
    })
}

// 升级卡
export function memberCardUpgrade(params) {
    return request({
        url: '/member/user/upgrade',
        method: 'post',
        data: params
    })
}
// 获取用户详情编辑查看校验
export function getDetailCheck() {
    return request({
        url: '/sys/store/detail/check',
        method: 'get',
    })
}
// 查询用户转通享余额记录
export function getTransferBalanceRecord(params) {
    return request({
        url: '/member/transferBalanceRecord/page',
        method: 'get',
        params,
    })
}
